<template>
	<v-card class="shadow-blue pa30 congrate-container">
		<v-img class="image mb-4" src="@/assets/images/goToReport/end-parcours.png"></v-img>
		<div>
			<h2 class="ortho-results-title mb-1">
				Félicitations, parcours <span v-if="parcours" class="parcours-name">{{ parcours.name.toLowerCase() }}</span> terminé !
			</h2>
			<p class="ortho-description ortho-text-gray">Découvre ta performance</p>
		</div>
	</v-card>
</template>

<script>
export default {
	name: 'CongrateFinishParcours',
	props: {
		parcours: Object,
	},
};
</script>

<style lang="scss" scoped>
.congrate-container {
	text-align: center;
	border-radius: 8px;
	padding: 0.5rem 0.2rem;
	background-color: white;
	.image {
		margin: 0 auto;
		width: 170px;
		@media screen and (max-width: 960px) {
			width: 130px;
		}
	}
	.parcours-name {
		text-transform: capitalize;
	}
}
</style>
