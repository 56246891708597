<template>
	<v-container>
		<div class="container-block">
			<CongrateFinishParcours
				:parcours="thisParcours"
				:class="{ 'mt-7': $vuetify.breakpoint.mdAndUp, 'mt-2': $vuetify.breakpoint.smAndDown }"
			/>
			<div :class="{ 'pt-5 pb-5': $vuetify.breakpoint.mdAndUp, 'pt-2 pb-2': $vuetify.breakpoint.smAndDown }"></div>
			<TotalEarnedPoints :parcours="thisParcours" />
			<div :class="{ 'pt-5 pb-5': $vuetify.breakpoint.mdAndUp, 'pt-2 pb-2': $vuetify.breakpoint.smAndDown }"></div>
			<RulesStatistics :parcours="thisParcours" />
			<v-btn
				v-if="notRedoExercise"
				depressed
				large
				dark
				color="black"
				class="next-btn hvr-grow"
				@click="directToUnlockParcours"
			>
				<span class="white--text">Voir le parcours suivant</span>
			</v-btn>
			<div :class="{ 'pt-5 pb-5': $vuetify.breakpoint.mdAndUp, 'pt-2 pb-2': $vuetify.breakpoint.smAndDown }"></div>
		</div>
	</v-container>
</template>

<script>
import { mapGetters } from 'vuex';

import CongrateFinishParcours from '@/components/reports/parcours/CongrateFinishParcours';
import TotalEarnedPoints from '@/components/reports/parcours/TotalEarnedPoints';
import RulesStatistics from '@/components/reports/parcours/RulesStatistics';
import { changeBgColorById } from '@/utils/css-util.js';
import { setZindexByClassNames } from '@/utils/helper.js';

export default {
	name: 'ReportParcours',
	components: { CongrateFinishParcours, TotalEarnedPoints, RulesStatistics },
	data() {
		return { thisParcours: null };
	},
	computed: {
		...mapGetters('profile', {
			formationProgress: 'formationProgress',
		}),
		notRedoExercise() {
			return this.$route.query.notRedo === 'true';
		},
	},
	async created() {
		const formationProgressRes = await this.$store.dispatch('profile/formationProgress', {
			idFormation: this.$route.params.idFormation,
		});
		const idParcours = this.$route.params.idParcours;
		const indexOfParcours = formationProgressRes.config.parcours_list.findIndex(
			(parcoursItem) => parcoursItem.parcours_id._id === idParcours
		);
		if (indexOfParcours !== -1)
			this.thisParcours = formationProgressRes.config.parcours_list[indexOfParcours].parcours_id;
	},
	mounted() {
		window.scrollTo({ top: 0, behavior: 'smooth' });
		changeBgColorById('reportIndex', '#F0F0FC');
		setZindexByClassNames(['navbar'], 1);
	},
	methods: {
		directToUnlockParcours() {
			this.$router.push({ name: 'ChooseParcousToUnlock', params: { idFormation: this.$route.params.idFormation } });
		},
	},
};
</script>

<style lang="scss" scoped>
.container-block {
	margin: 0 auto;
	padding-bottom: 40px;
	max-width: 800px;
}
.next-btn {
	position: fixed;
	bottom: 30px;
	left: 50%;
	transform: translateX(-50%);
}
</style>
